<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Sakin Bilgileri Toplu Güncelleme</template>
    <template slot="body">
      <v-form ref="formTemplate" @submit.prevent="handleDownloadFormSubmit">
        <v-row dense>
          <v-col>
            <rs-action @click="handleSelectAll()">Tümünü Seç</rs-action>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col v-for="(item, index) in availableColumns" :key="index" sm="4">
            <v-checkbox
              v-model="selectedColumns"
              :label="item.text"
              :value="item.value"
              hide-details="auto"
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="hide()"
          @submit.prevent="handleFormSubmit"
          hide-save-close
          hide-cancel
          save-label="Şablonu İndir"
          v-if="selectedColumns.length > 0"
        />
        <p>
          <strong>ÖNEMLİ:</strong> Aktarımın başarıyla yapılabilmesi için şablon
          formatında değişiklik <strong>yapmamanız</strong> gerekir.
        </p>
      </v-form>

      <v-divider class="my-10"></v-divider>

      <v-form ref="form" @submit.prevent="handleFormSubmit">
        <v-row dense>
          <v-col sm="12">
            <rs-file
              label="Dosya"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.oasis.opendocument.spreadsheet"
              :rules="[rules.required]"
              :readonly="disabled"
              :filled="disabled"
              @change="handleFileChange"
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="hide()"
          @submit.prevent="handleFormSubmit"
          hide-submit
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";

import hasForm from "@/view/mixins/hasForm";

export default {
  name: "ImportForm",
  mixins: [hasForm],
  computed: {
    ...mapGetters(["clusterId"]),
    templateDownloadTarget() {
      return Vue.axios.defaults.baseURL + "templates/import/cluster";
    },
  },
  data() {
    return {
      file: null,
      availableColumns: [
        {
          text: "İsim",
          value: "name",
        },
        {
          text: "Telefon",
          value: "phone",
        },
        {
          text: "TC Kimlik No",
          value: "identity_number",
        },
        {
          text: "Pasaport No",
          value: "passport_no",
        },
        {
          text: "Kan Grubu",
          value: "blood_type",
        },
        {
          text: "Tebligat Adresi",
          value: "address_legal",
        },
        {
          text: "İş Adresi",
          value: "address_work",
        },
        {
          text: "Açıklama",
          value: "description",
        },
        {
          text: "Banka Entegrasyon Anahtar Kelimeleri",
          value: "bank_keywords",
        },
        {
          text: "Telefon 2",
          value: "phone_2",
        },
        {
          text: "İş Telefonu",
          value: "phone_work",
        },
        {
          text: "Ev Telefonu",
          value: "phone_home",
        },
        {
          text: "E-posta",
          value: "email",
        },
        {
          text: "E-posta 2",
          value: "email_2",
        },
        {
          text: "Plakalar",
          value: "vehicles",
        },
        {
          text: "Durum",
          value: "is_active",
        },
      ],
      selectedColumns: [],
    };
  },
  methods: {
    show() {
      this.$nextTick(() => {
        this.$refs.sidePanel.show();
      });
    },
    hide() {
      this.formData.file = null;
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return;
      }

      this.isLoading = true;
      const data = new FormData();
      data.append("file", this.file);
      data.append("cluster_id", this.$route.params.id);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      this.$api
        .post(`clusters/${this.clusterId}/import-residents`, data, config)
        .then((response) => {
          this.$toast.success("Kaydedildi");
          this.$emit("saved", response.data.data);
          this.hide();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleDownloadFormSubmit() {
      if (!this.selectedColumns.length) {
        this.$toast.error("Güncellemek için en az bir sütun seçmelisiniz.");
        return false;
      }

      const params = {
        columns: this.selectedColumns,
      };

      this.isLoading = true;

      this.$api
        .post(`clusters/${this.clusterId}/import-residents-template`, params)
        .then((response) => {
          const anchor = document.createElement("a");
          anchor.href = response.data.data.url;
          anchor.target = "_blank";
          anchor.click();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleFileChange(file) {
      if (!file) {
        this.file = null;
        return;
      }

      const allowedMimes = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.oasis.opendocument.spreadsheet",
      ];

      if (allowedMimes.indexOf(file.type) === -1) {
        this.file = null;
        this.$toast.error("Sadece XLSX veya ODS yükleyebilirsiniz.");
        return;
      }

      this.file = file;
    },
    handleSelectAll() {
      this.selectedColumns = [];
      for (const index in this.availableColumns) {
        this.selectedColumns.push(this.availableColumns[index].value);
      }
    },
  },
};
</script>

<template>
  <v-card :loading="isLoading">
    <v-card-text v-if="clusterId">
      <v-data-table
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="Sakin ve Kullanıcı Tanımları"
            icon="mdi-account-badge"
            :add-route="
              can('edit-resident')
                ? { name: 'definitions.residents.create' }
                : null
            "
            @clear-filters="$refs.headSearch.reset"
            @reload="loadList"
            @click:edit="handleEditClick"
            :hide-edit="!can('edit-resident')"
            :edit-enabled="selectedItems.length === 1"
            :show-delete="can('delete-user')"
            :delete-enabled="selectedItems.length > 0"
            @click:delete="handleBatchDeleteClick"
            :search.sync="search.query"
            v-bind="titleBarAttrs"
          >
            <template slot="buttons:append">
              <v-menu v-if="can('edit-resident')">
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="float-end ms-2"
                    color="pink"
                    outlined
                    small
                    :disabled="selectedItems.length !== 1"
                    v-on="on"
                  >
                    Yeni Parola Gönder
                  </v-btn>
                </template>
                <v-list>
                  <!-- <v-list-item>
                    <a href="#" @click.prevent="handleSendPasswordEmailClick">
                      E-postayla Gönder
                    </a>
                  </v-list-item> -->
                  <v-list-item>
                    <a href="#" @click.prevent="handleSendPasswordSMSClick">
                      SMS'le Gönder
                    </a>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-btn
                class="float-end ms-2"
                color="pink"
                outlined
                small
                v-if="$refs.importForm && can('edit-resident')"
                @click="$refs.importForm.show()"
              >
                Toplu Güncelleme
              </v-btn>
            </template>

            <template slot="buttons:prepend">
              <rs-select
                class="float-end me-2"
                style="max-width: 150px"
                label="Sakin Filtrele"
                :items="userStatusList"
                v-model="userStatus"
                clearable
                dense
              />
            </template>
          </rs-table-title-bar>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <router-link
            class="btn btn-icon btn-sm btn-clean"
            :to="{
              name: 'definitions.residents.edit',
              params: { id: item.id },
            }"
          >
            <i class="menu-icon mdi mdi-pencil"></i>
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.name="{ item }">
          <router-link
            :to="{
              name: 'definitions.residents.show',
              params: { id: item.id },
            }"
          >
            {{ item.name }}
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.door_number="{ item }">
          <router-link
            :to="{
              name: 'definitions.houses.show',
              params: { id: item.house_id },
            }"
            v-if="item.house_id"
          >
            {{ item.door_number }}
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.house_count="{ value }">
          <rs-table-cell-number :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.is_active="{ item }">
          <rs-table-cell-boolean :value="item.is_active" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.can_receive_bills="{ item }">
          <rs-table-cell-boolean :value="item.can_receive_bills" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.can_collect_money="{ item }">
          <rs-table-cell-boolean :value="item.can_collect_money" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.is_demo="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.role="{ value }">
          {{ value.join(", ") }}
        </template>
      </v-data-table>

      <ImportForm ref="importForm" @saved="loadList()" />

      <rs-confirm
        ref="confirmDelete"
        :loading="isLoading"
        @confirmed="handleDeleteConfirmed"
        @cancelled="$refs.confirmDelete.hide()"
      />

      <rs-confirm
        ref="confirmSendPasswordEmail"
        :loading="isLoading"
        @confirmed="handleSendPasswordEmailConfirmed"
        @cancelled="$refs.confirmSendPasswordEmail.hide()"
      />

      <rs-confirm
        ref="confirmSendPasswordSMS"
        :loading="isLoading"
        @confirmed="handleSendPasswordSMSConfirmed"
        @cancelled="$refs.confirmSendPasswordSMS.hide()"
      />
    </v-card-text>

    <v-card-text v-else>
      Listeyi görmek için üst menüden bir toplu yaşam alanı seçin.
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { filtersToURL, hasExport, hasPermissions } from "@/view/mixins";
import ImportForm from "./ImportForm.vue";

export default {
  mixins: [filtersToURL, hasExport, hasPermissions],
  components: { ImportForm },
  computed: {
    ...mapGetters([
      "blocks",
      "cluster",
      "clusterId",
      "countryList",
      "houseTypes",
      "roleList",
      "userCommercialTypeList",
      "houseUserTypeList",
    ]),
  },
  watch: {
    clusterId() {
      this.loadList();
    },
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.options.page = 1;
        this.setURLParams();
      }),
      deep: true,
    },
    userStatus(newValue) {
      if (newValue === 1) {
        this.search = Object.assign(this.search, {
          wrong_number: null,
          has_mobile_app: true,
        });
      } else if (newValue === 2) {
        this.search = Object.assign(this.search, {
          wrong_number: null,
          has_mobile_app: false,
        });
      } else if (newValue === 3) {
        this.search = Object.assign(this.search, {
          wrong_number: true,
          has_mobile_app: null,
        });
      } else {
        this.search = Object.assign(this.search, {
          wrong_number: null,
          has_mobile_app: null,
        });
      }

      this.loadList();
    },
  },
  data() {
    return {
      userStatus: null,
      userStatusList: [
        {
          id: 1,
          name: "Mobil Uygulama Kullananlar",
        },
        {
          id: 2,
          name: "Mobil Uygulama Kullanmayanlar",
        },
      ],
      search: {
        is_active: true,
      },
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
          hide: () => !this.can("edit-resident"),
        },
        {
          text: this.$t("labels.floor"),
          value: "floor",
          searchable: "text",
        },
        {
          text: this.$t("labels.block"),
          value: "block",
          searchable: "multiselect",
          options: () => this.blocks,
          itemText: "name",
          width: "75px",
          align: "center",
        },
        {
          text: this.$t("labels.house_type"),
          value: "house_type",
          searchable: "multiselect",
          options: () => this.houseTypes,
          itemText: "name",
        },
        {
          text: "Adı Soyadı",
          value: "name",
          searchable: "text",
          width: "155px",
        },
        {
          text: "BB No",
          value: "door_number",
          searchable: "text",
          width: "75px",
          align: "center",
        },
        {
          text: this.$t("labels.phone"),
          value: "phone",
          searchable: "text",
        },
        {
          text: this.$t("labels.identity_no"),
          value: "identity_number",
          searchable: "text",
        },
        {
          text: this.$t("labels.status"),
          value: "is_active",
          searchable: "isActive",
          align: "center",
        },
        {
          text: this.$t("labels.user_role"),
          value: "role",
          searchable: "multiselect",
          options: () => this.roleList,
          itemText: "display_name",
        },
        {
          text: "Oturum Şekli",
          value: "house_user_type",
          searchable: "multiselect",
          options: () => this.houseUserTypeList,
        },
        {
          text: "Vatandaşlık",
          value: "citizenship",
          searchable: "select",
          options: () => this.countryList,
          itemText: "name",
        },
        {
          text: this.$t("labels.user_commercial_type"),
          value: "user_commercial_type",
          searchable: "select",
          options: () => this.userCommercialTypeList,
        },
        {
          text: "BB Sayısı",
          value: "house_count",
          searchable: "number",
          sortable: true,
          align: "end",
        },
        {
          text: "Demo",
          value: "is_demo",
          searchable: "yesNo",
        },
      ],
      titleBarAttrs: {
        exportUrl: () => `clusters/${this.clusterId}/residents`,
        exportParams: this.getParams,
      },
    };
  },
  mounted() {
    this.generateExportColumns(this.headers);
  },
  methods: {
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      return params;
    },
    loadList() {
      if (this.isLoading || !this.clusterId) {
        return;
      }

      this.isLoading = true;
      this.list = [];
      this.selectedItems = [];

      const params = this.getParams();

      this.$api
        .query(`clusters/${this.clusterId}/residents`, { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleEditClick() {
      if (this.selectedItems.length === 1) {
        this.$router.push({
          name: "definitions.residents.edit",
          params: { id: this.selectedItems[0].id },
        });
      }
    },
    handleBatchDeleteClick() {
      this.$refs.confirmDelete.show(
        `${this.selectedItems.length} tane sakini silmek istediğinizden emin misiniz?`
      );
    },
    handleDeleteConfirmed() {
      if (this.isLoading) return false;

      this.isLoading = true;
      const promises = [];
      const selectedItems = this.selectedItems;

      for (const index in this.selectedItems) {
        const promise = this.$api
          .delete(`residents/${this.selectedItems[index].id}`)
          .then(() => selectedItems.splice(index, 1));
        promises.push(promise);
      }

      this.selectedItems = selectedItems;

      Promise.all(promises)
        .then(() => {
          this.$toast.success("Seçilen sakinler silindi");
          setTimeout(() => this.loadList(), 100);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.$refs.confirmDelete.hide();
          this.isLoading = false;
        });
    },
    handleSendPasswordEmailClick() {
      if (!this.selectedItems[0].user_id) {
        this.$toast.error(
          "Bu sakine tanımlı bir kullanıcı bulunmuyor. Lütfen TC kimlik numarası girin."
        );
        return;
      }

      this.$refs.confirmSendPasswordEmail.show(
        `${this.selectedItems.length} tane sakinin parolasını sıfırlamak istediğinizden emin misiniz?`
      );
    },
    handleSendPasswordEmailConfirmed() {
      this.$refs.confirmSendPasswordEmail.hide();
      this.isLoading = true;

      this.$api
        .post(`users/${this.selectedItems[0].user_id}/password-reset`, {
          via: "email",
        })
        .then(() => {
          this.$toast.success("Parola sıfırlama Email(leri) gönderildi");
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleSendPasswordSMSClick() {
      this.$refs.confirmSendPasswordSMS.show(
        `${this.selectedItems.length} tane sakinin parolasını sıfırlamak istediğinizden emin misiniz?`
      );
    },
    handleSendPasswordSMSConfirmed() {
      this.$refs.confirmSendPasswordSMS.hide();
      this.isLoading = true;
      this.$api
        .post(`users/${this.selectedItems[0].user_id}/password-reset`, {
          via: "sms",
        })
        .then(() => {
          this.$toast.success("Parola sıfırlama SMS(leri) gönderildi");
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
